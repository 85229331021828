import React, { useEffect } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { usePrismicPreview } from "gatsby-source-prismic";
import getSlugFromPrismicType from "../../src/util/getSlugFromPrismicType";
const PreviewPage = ({ location }) => {
    const staticPages = ["home", "about", "project_listing", "news_listing", "contact_u", "careers"]

    const { allPrismicProject, allPrismicLegal, allPrismicNewsArticle, allPrismicInsight, sitePlugin } = useStaticQuery(graphql`
        {
            allPrismicProject {
                nodes {
                    prismicId
                }
            }
            allPrismicLegal {
                nodes {
                    prismicId
                }
            }
            allPrismicNewsArticle {
                nodes {
                    prismicId
                }
            }
            allPrismicInsight {
                nodes {
                    prismicId
                }
            }
            sitePlugin(name: {eq: "gatsby-source-prismic"}) {
                pluginOptions {
                    repositoryName
                }
            }
        }
            
        `);
    const projectUids = allPrismicProject.nodes.map(node => node.prismicId);
    const legalUids = allPrismicLegal.nodes.map(node => node.prismicId);
    const newsUids = allPrismicNewsArticle.nodes.map(node => node.prismicId);
    const insightsId = allPrismicInsight.nodes.map(node => node.prismicId);
    const { previewData, path } = usePrismicPreview( {
        repositoryName: sitePlugin.pluginOptions.repositoryName,
        pathResolver: ({ node, key, value }) => doc => {
            // Your link resolver
            if(staticPages.includes(doc.type)) {
                return getSlugFromPrismicType(doc);
            }
            const previewedUID = doc.id;
            if(doc.type === "project") {
                if(projectUids.includes(previewedUID)) {
                    return getSlugFromPrismicType(doc);
                } else {
                    return "/unpublishedProject"
                }
            }
            if(doc.type === "legal") {
                if(legalUids.includes(previewedUID)) {
                    return getSlugFromPrismicType(doc);
                } else {
                    return "/unpublishedLegal"
                }
            }
            if(doc.type === "news_article") {
                if(newsUids.includes(previewedUID)) {
                    return getSlugFromPrismicType(doc);
                } else {
                    return "/unpublishedNewsArticle"
                }
            }
            if(doc.type === "insight") {
                if(insightsId.includes(previewedUID)) {
                    return getSlugFromPrismicType(doc);
                } else {
                    return "/unpublishedInsight"
                }
            }


        },
    });
    useEffect(() => {
        if (previewData && path) {
            window.__PRISMIC_PREVIEW_DATA__ = previewData;
            navigate(path);
        }
    }, [previewData, path]);

    return <div>Loading preview...</div>;
};

export default PreviewPage;
