const staticPages = {"home": "/", "about": "/about", "project_listing": "/projects", "news_listing": "/news", "contact_u": "/contact-us", "careers": "/careers", "insights_landing" : "/insights"};
const dynamicPages = {"legal": "/", "news_article": "/news/", "project": "/projects/", "insight" : "/insights/"};
function getSlugFromPrismicType(doc) {
    if(staticPages[doc.type]) {
        return staticPages[doc.type];
    } else if(dynamicPages[doc.type] && doc.uid) {
        return `${dynamicPages[doc.type]}${doc.uid}`
    }
    return "/";
}

module.exports = getSlugFromPrismicType;